import React from 'react';

// Less
import './Map.less';

const Map = () => (
  <div className='mb-4'>
    <div className="map">
      <div className="map__container">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2687.3901539036297!2d23.57382831544795!3d47.657416592459576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4737dc68b8ac21bb%3A0x3bdd4b8e94d30d5f!2sStrada+Gheorghe+%C8%98incai+31%2C+Baia+Mare!5e0!3m2!1sen!2sro!4v1539352139884"
          width="100%"
          height="350px"
          frameBorder="0"
          allowFullScreen
          name="map"
        />
      </div>
      <form
        className="map__form"
        action="http://maps.google.com/maps"
        method="get"
        target="_blank"
      >
        <label htmlFor="saddr">Enter your location</label>
        <input type="text" name="saddr" aria-label="text-input" />
        <input
          type="hidden"
          name="daddr"
          value="31 Gheorghe Sincai Street, Baia Mare, 430311"
          aria-label="text-default"
        />
        <button type="submit">Get directions </button>
      </form>
    </div>
    <div className="map">
    <div className="map__container">
      <iframe
        title="map"
        src="https://maps.google.com/maps?q=Harmanului 17E 500222&t=&z=16&ie=UTF8&iwloc=&output=embed"
        width="100%"
        height="350px"
        frameBorder="0"
        allowFullScreen
        name="map"
      />
    </div>
    <form
      className="map__form"
      action="http://maps.google.com/maps"
      method="get"
      target="_blank"
    >
      <label htmlFor="saddr">Enter your location</label>
      <input type="text" name="saddr" aria-label="text-input" />
      <input
        type="hidden"
        name="daddr"
        value="Harmanului 17E 500222"
        aria-label="text-default"
      />
      <button type="submit">Get directions </button>
    </form>
  </div>
  </div>
);

export default Map;
