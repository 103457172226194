import {graphql, useStaticQuery} from 'gatsby';

export const useContact = () => {
  const {contactJson} = useStaticQuery(graphql`
    query Contact {
      contactJson {
        contactDetails {
          email
          name
          position
        }
        location {
          address
          site
          telephone {
            title
            number
          }
          city
        }
      }
    }
  `);

  return contactJson;
};
