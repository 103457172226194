import React from 'react';

// Bootstrap
import {Col, Row} from 'react-bootstrap';

// Layout
import Layout from '../containers/layout/Layout';

// Components
import Banner from '../components/common/banner/Banner';
import LocationWidget from '../components/contact/location-widget/LocationWidget';
import Map from '../components/contact/map/Map';

// Image
import bannerImage from '../../static/assets/background/bg-bokeh-blue.png';

// Data
import {useContact} from '../graphql/contact/contact';

const Contact = () => {
  const {contactDetails, location} = useContact();
  const bannerOpacity = {
    opacity: '0.75',
  };

  const bannerOverlay = {
    backgroundImage: `url(${bannerImage})`,
  };

  const contactStyles = {
    marginRight: '0',
    marginLeft: '0',
  };

  return (
    <Layout>
      <Banner
        bannerOpacity={bannerOpacity}
        bannerOverlay={bannerOverlay}
        className="bannerContact"
        contactDetails={contactDetails}
      >
        CONTACT
      </Banner>
      <Row style={contactStyles}>
        <Col md={6}>
          <Map />
        </Col>
        <Col md={6}>
          <LocationWidget locations={location} />
        </Col>
      </Row>
    </Layout>
  );
};

export default Contact;
