import React from 'react';

// Bootstrap
import {Container, Col} from 'react-bootstrap';

// Less
import './LocationWidget.less';

const LocationWidget = ({locations}) => {
  const locationsWidget = locations.map((location, key) => {
    return (
      <Col className="location-widget__card" xs={12} key={key}>
        <p className="location-widget__city">{location.city}</p>
        <address className="location-widget__address">
          {location.address.map((address, index) => (
            <p key={index}>{address}</p>
          ))}
        </address>
        {location.telephone.map((tel, index) => {
          return (
            <p className="location-widget__telephone" key={index}>
              {tel.title}
              &nbsp;
              <a href={`tel:${tel.number}`}>{tel.number}</a>
            </p>
          );
        })}
        <p className="location-widget__site">
          <a href={`https://${location.site}`} target="__blank">
            {location.site}
          </a>
        </p>
      </Col>
    );
  });
  return (
    <div className="location-widget">
      <Container>{locationsWidget}</Container>
    </div>
  );
};

export default LocationWidget;
